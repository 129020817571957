@import "~@fortawesome/fontawesome-free/css/all.css";
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-balham.css";
@import "highlight.js/scss/lioshi.scss";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.route-container {
  position: fixed;
  top: 112px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.busy-layer {
  display: none;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(200, 200, 200, 0.6);
  z-index: 99999;
  animation-duration: 0.5s;
  position: relative;
  .icon {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.busy-layer.busy-visible {
  display: block;
  position: fixed;
  visibility: visible;
  opacity: 1;
  animation-name: fadeIn;
  z-index: 999999999;
}

.busy-layer.busy-hidden {
  display: block;
  position: fixed;
  visibility: visible;
  opacity: 0;
  // This gives better style than animating, because this starts from current value and doesn't jump to 1 (if fadeIn takes less than animation duration)
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  //animation-name: fadeOut;
}

.margin-bottom {
  margin-bottom: 10px !important;
}

.margin-bottom-top {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.margin-right {
  margin-right: 10px !important;
}

code.hljs {
  border-radius: 4px;
}

textarea,
input {
  caret-color: white !important;
}

a {
  color: white;
  text-decoration: None;
}

a:hover {
  text-decoration: underline;
}
